import AvField from "availity-reactstrap-validation/lib/AvField"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import React, { useEffect, useState, useMemo } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Button,
  Input,
  CardTitle,
} from "reactstrap"
import Select from "react-select"
import APIS from "../../apis"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import countryList from "react-select-country-list"
import cresusFond from "../../assets/images/cresuFond.jpg"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import axios from "axios";

const Register = props => {
  const [disbutReg, setDisbutReg] = useState(true)
  const [nomPrenom, setNomPrenom] = useState("")
  const [nomPrenomAr, setNomPrenomAr] = useState("")
  const [prenomPere, setPrenomPere] = useState("")
  const [prenomPereAr, setPrenomPereAr] = useState("")
  const [gender, setGender] = useState(1)
  const [date, setDate] = useState("")
  const [gouvernoratNaiss, setGouvernoratNaiss] = useState("")
  const [gouvernoratNaissAr, setGouvernoratNaissAr] = useState("")
  const [selectNationnalite, setSelectNationnalite] = useState("")
  const [numero, setNumero] = useState("")
  const [etatCivil, setEtatCivil] = useState(1)
  const [file, setFile] = useState(null)
  const [rue, setRue] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [gouvernorat, setGouvernorat] = useState("")
  const [delegation, setDelegation] = useState("")
  const [tel, setTel] = useState("")
  const [email, setEmail] = useState("")
  const [anneeBac, setAnneeBac] = useState("")
  const [section, setSection] = useState("")
  const [mention, setMention] = useState("")
  const [session, setSession] = useState("")
  const [payObtention, setPayObtention] = useState("")
  const [premiereIns, setPremiereIns] = useState("")
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [display, setDisplay] = useState(0)
  const options = useMemo(() => countryList().getData(), [])
  const [annee, setAnnee] = useState("");
  useEffect(async () => {
    const res = await axios
        .get("https://scolarite.backcresus-institut.ovh/api/annee")
        .then(res => {
          const resAnnee = axios.post(
              "https://scolarite.backcresus-institut.ovh/api/annee/get_by_id",
              { id: res.data.Annee[0].CurrentAnnee[0].value }
          ).then(resAnnee=>{
            setAnnee(resAnnee.data.Annee.id)
            axios.get("https://scolarite.backcresus-institut.ovh/api/cycle/select",{
              headers: {
                'X-Frame-Options' : "DENY",
                'annee':resAnnee.data.Annee.id,
                'Content-Type': 'application/json',
              },
            }).then(resC => {
              setCycle(resC.data.Cycle)
            })
            // localStorage.setItem("anneeScolaire", JSON.stringify(resAnnee.data))
          })
        })
    // const resC = await APIS.get("cycle/select").then(resC => {
    //   setCycle(resC.data.Cycle)
    // })



  }, [])

  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    if (cycle_id == 1) {
      axios.get("https://scolarite.backcresus-institut.ovh/api/niveau/select",{
        headers: {
          'X-Frame-Options' : "DENY",
          'annee':annee,
          'Content-Type': 'application/json',
        },
      }).then(res => {
        setNiveau(res.data.Niveau)
      })
    } else {
      axios.get("https://scolarite.backcresus-institut.ovh/api/niveau/select_mastere",{
        headers: {
          'X-Frame-Options' : "DENY",
          'annee':annee,
          'Content-Type': 'application/json',
        },
      }).then(res => {
        setNiveau(res.data.Niveau)
      })
    }
  }

  const getSpecialite = async event => {
    var niveau_id = event.value
    setSelectNiveau(event)
    setSelectSpecialite("")
    axios.post("https://scolarite.backcresus-institut.ovh/api/specialite/get_spec_by_cyc_niv",{
      niveau_id: niveau_id,
      cycle_id: selectCycle.value,
    },{
      headers: {
        'X-Frame-Options' : "DENY",
        'annee':annee,
        'Content-Type': 'application/json',
      },
    }).then(res => {
      setSpecialite(res.data.Specialites)
    })
  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const save = async () => {

    if (date != "") {
      let dateNaiss = date
      let month = "" + (dateNaiss.getMonth() + 1)
      let day = "" + dateNaiss.getDate()
      let year = dateNaiss.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    //Test si file vide
    var pathFile = ""
    if (file != null) {
      pathFile = file.name
    }
    var nationalite_id = selectNationnalite.label || 0
    if (nomPrenom == "") {
      toast.error("⛔ Nom et Prénom obligatoire !! ", {
        containerId: "A",
      })
    } else if (nomPrenomAr == "") {
      toast.error("⛔ Nom et Prénom arabe obligatoire !!", {
        containerId: "A",
      })
    } else if (prenomPere == "") {
      toast.error("⛔ Nom pére obligatoire !!", {
        containerId: "A",
      })
    } else if (prenomPereAr == "") {
      toast.error("⛔ Nom pére arabe obligatoire !!", {
        containerId: "A",
      })
    } else if (numero == "") {
      toast.error("⛔ Identifiant obligatoire !!", {
        containerId: "A",
      })
    } else if (tel == "") {
      toast.error("⛔ Téléphone obligatoire !!", {
        containerId: "A",
      })
    } else if (email == "") {
      toast.error("⛔ Email obligatoire !!", {
        containerId: "A",
      })
    } else if (selectCycle == "") {
      toast.error("⛔ Cycle obligatoire !!", {
        containerId: "A",
      })
    } else if (selectNiveau == "") {
      toast.error("⛔ Niveau obligatoire !!", {
        containerId: "A",
      })
    } else if (selectSpecialite == "") {
      toast.error("⛔ Spécialité obligatoire !!", {
        containerId: "A",
      })
    } else if (date != "") {
      setDisbutReg(false)
      const res = await APIS.post("renseignement/add", {
        nom: nomPrenom,
        nom_ar: nomPrenomAr,
        prenonPere: prenomPere,
        prenonPere_ar: prenomPereAr,
        sexe: gender,
        dateNaissance: convertDate,
        gouvernoratNaissance: gouvernoratNaiss,
        gouvernoratNaissance_ar: gouvernoratNaissAr,
        nationalite: nationalite_id,
        cin: numero,
        etatCivil: etatCivil,
        fichier: pathFile,
        rue: rue,
        codePostal: codePostal,
        gouvernorat: gouvernorat,
        delegation: delegation,
        téléphone: tel,
        email: email,
        anneBac: anneeBac,
        section: section,
        mention: mention,
        session_etd: session,
        paysObtention: payObtention,
        preméreInscription: premiereIns,
        cycle_id: selectCycle.value,
        niveau_id: selectNiveau.value,
        specialite_id: selectSpecialite.value,
      },{
            headers: {
              'X-Frame-Options' : "DENY",
              'annee': annee,
              'Content-Type': 'application/json',
            },
          }).then(res => {
        if (res.data.status === 200) {
          if (pathFile != "") {
            const formData = new FormData()
            formData.append("document", file)
            formData.append("cin", numero)
            const resA = APIS.post(
              "renseignement/import_photo",
              formData,
              options,
                {
                  headers: {
                    'X-Frame-Options' : "DENY",
                    'annee': annee,
                    'Content-Type': 'application/json',
                  },
                }
            ).then(resA => {
              setDisplay(1)
              const timer = setTimeout(() => {
                props.history.push("/DetailIns?cin=" + numero)
              }, 3000)
            })
          } else {
            setDisplay(1)
            const timer = setTimeout(() => {
              props.history.push("/DetailIns?cin=" + numero)
            }, 3000)
          }
        } else if (res.data.errNum === 300) {
          toast.error("⛔ CIN déja existe !!", {
            containerId: "A",
          })
        } else if (res.data.errNum === 301) {
          toast.error("⛔ E-mail déja existe !!", {
            containerId: "A",
          })
        } else if (res.data.errNum === 302) {
          toast.error("⛔ CIN invalide !!", {
            containerId: "A",
          })
        } else if (res.data.errNum === 500) {
          toast.error("⛔ E-mail invalide !!", {
            containerId: "A",
          })
        }
      })
    } else {
      toast.error("⛔ Date de naissance obligatoire !!", {
        containerId: "A",
      })
    }
  }

  return (
    <div
      style={{
        backgroundImage: `url(${cresusFond})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "auto",
        width: "auto",
      }}
    >
      {display === 0 ? (
        <div style={{ paddingTop: "7%" }}>
          <Container>
            <Row className="justify-content-center">
              <Col md={12} lg={12} xl={12}>
                <Card className="">
                  <div>
                    {" "}
                    <div>
                      <Row>
                        <Col className="justify-content-center pt-4">
                          <h1
                            style={{
                              color: "#485ec4",
                              textAlign: "center",
                            }}
                          >
                            <p>FICHE DES RENSEIGNEMENTS</p>
                            <p>A.U 2023/2024</p>
                          </h1>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-4">
                      <CardTitle
                        style={{ color: "#556ee6" }}
                        className="h4 mb-4 mt-4"
                      >
                        Informations générales :
                      </CardTitle>
                      <AvForm className="form-horizontal">
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Nom et Prénom :
                              </Label>
                              <AvField
                                name="nomprenom"
                                placeholder="Entrer Nom et Prénom"
                                type="text"
                                errorMessage="* Nom et Prénom obligatoire"
                                className="form-control "
                                validate={{ required: { value: true } }}
                                onChange={e => setNomPrenom(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الإسم و اللقب
                              </Label>
                              <AvField
                                name="nomprenomar"
                                placeholder="أدخل الاسم و اللقب"
                                type="text"
                                errorMessage="*الاسم و اللقب إجباري"
                                className="form-control rtl"
                                validate={{ required: { value: true } }}
                                onChange={e => setNomPrenomAr(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Prénom Père :
                              </Label>
                              <AvField
                                name="prenompere"
                                placeholder="Entrer Prénom du Père"
                                type="text"
                                errorMessage="* nom obligatoire"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                onChange={e => setPrenomPere(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : اسم الاب
                              </Label>
                              <AvField
                                name="prenomperear"
                                placeholder="أدخل الاسم الأول للأب"
                                type="text"
                                errorMessage="*اسم الأب إجباري"
                                className="form-control rtl"
                                validate={{ required: { value: true } }}
                                onChange={e => setPrenomPereAr(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="d-block mb-3">Sexe :</Label>
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  id="customRadioInline1"
                                  name="customRadioInline1"
                                  className="form-check-input"
                                  defaultChecked
                                  onClick={() => setGender(1)}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customRadioInline1"
                                >
                                  Masculin
                                </Label>
                              </div>
                              &nbsp;
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  id="customRadioInline2"
                                  name="customRadioInline1"
                                  className="form-check-input"
                                  onClick={() => setGender(2)}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customRadioInline2"
                                >
                                  Feminin
                                </Label>
                              </div>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Date de naissance :{" "}
                              </Label>
                              <Col md={12} className="pr-0">
                                <DatePicker
                                  className="form-control ddate"
                                  selected={date}
                                  onChange={setDate}
                                  dateFormat="dd/MM/yyyy"
                                />
                              </Col>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Gouvernorat de naissance :
                              </Label>
                              <AvField
                                name="nom"
                                placeholder="Entrer Gouvernorat de naissance"
                                type="text"
                                className="form-control"
                                onChange={e =>
                                  setGouvernoratNaiss(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الولاية
                              </Label>
                              <AvField
                                name="nom"
                                placeholder="أدخل الولاية"
                                type="text"
                                className="form-control rtl"
                                onChange={e =>
                                  setGouvernoratNaissAr(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="1">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Nationalité :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="10">
                            <Select
                              options={options}
                              isSearchable={true}
                              onChange={e => setSelectNationnalite(e)}
                            />
                          </Col>
                          <Col lg="1">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الجنسية
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="1">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                N° CIN :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="10">
                            <AvField
                              name="numero"
                              placeholder="Entrer identifiant"
                              type="text"
                              // errorMessage="Min 10 chiffres"
                              className="form-control"
                              // validate={{
                              //   required: { value: true },
                              //   min: { value: 8 },
                              //   max:{value:10}
                              // }}
                              onChange={e => setNumero(e.target.value)}
                            />
                          </Col>
                          <Col lg="1">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : رقم بطاقة التعريف الوطنية
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="1">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Etat Civil :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="10 center">
                            <div className="form-check form-check-inline">
                              <label
                                className="form-check-label left mg-r-30"
                                htmlFor="customRadioInline5"
                              >
                                Célibataire
                              </label>
                              <Input
                                type="radio"
                                id="customRadioInline5"
                                name="customRadioInline5"
                                className="form-check-input"
                                checked={etatCivil === 1}
                                onChange={e => setEtatCivil(1)}
                              />
                              <Label
                                className="form-check-label rtl"
                                htmlFor="customRadioInline5"
                              >
                                أعزب
                              </Label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <label
                                className="form-check-label left mg-r-30"
                                htmlFor="customRadioInline6"
                              >
                                Marié(e)
                              </label>
                              <Input
                                type="radio"
                                id="customRadioInline6"
                                name="customRadioInline5"
                                className="form-check-input"
                                checked={etatCivil === 2}
                                onChange={e => setEtatCivil(2)}
                              />
                              <Label
                                className="form-check-label rtl"
                                htmlFor="customRadioInline6"
                              >
                                متزوج
                              </Label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <label
                                className="form-check-label left mg-r-30"
                                htmlFor="customRadioInline7"
                              >
                                Divorcé(e)
                              </label>
                              <Input
                                type="radio"
                                id="customRadioInline7"
                                name="customRadioInline5"
                                className="form-check-input"
                                checked={etatCivil === 3}
                                onChange={e => setEtatCivil(3)}
                              />
                              <Label
                                className="form-check-label rtl"
                                htmlFor="customRadioInline7"
                              >
                                مطلق
                              </Label>
                            </div>
                          </Col>
                          <Col lg="1">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الحالة المدنية
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="1">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Photo :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="10">
                            <div className="mb-3">
                              <Input
                                onChange={e => addFile(e)}
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                          <Col lg="1">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الصورة
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-4 mt-4"
                        >
                          Adresse :
                        </CardTitle>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Rue :{" "}
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer rue"
                              type="text"
                              onChange={e => setRue(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : النهج{" "}
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Code postal :{" "}
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer code postal"
                              type="number"
                              onChange={e => setCodePostal(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الترقيم البريدي{" "}
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Gouvernorat :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer gouvernorat"
                              type="text"
                              onChange={e => setGouvernorat(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الولاية
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Délégation :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer délégation"
                              type="text"
                              onChange={e => setDelegation(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : المعتمدية
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Téléphone :{" "}
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <AvField
                              name="tel"
                              placeholder="Entrer téléphone"
                              type="number"
                              errorMessage="* Téléphone obligatoire"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                min: { value: 8 },
                              }}
                              onChange={e => setTel(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الهاتف
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                E-mail :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <AvField
                              name="email"
                              placeholder="Entrer un email valide"
                              type="email"
                              errorMessage="Email invalide"
                              onChange={e => setEmail(e.target.value)}
                              validate={{
                                required: { value: true },
                                email: { value: true },
                              }}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : البريد الإلكتروني
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-4 mt-4"
                        >
                          Baccalauréat
                        </CardTitle>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Année du bac :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer année du bac"
                              type="number"
                              onChange={e => setAnneeBac(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : سنة الباكالوريا
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Section :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer section"
                              type="text"
                              onChange={e => setSection(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الشعبة
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Mention :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer mention"
                              type="text"
                              onChange={e => setMention(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : ملاحظة
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Session :
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer session"
                              type="text"
                              onChange={e => setSession(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : الدورة
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {"Pays d'obtention :"}
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer pay d'obtention"
                              type="text"
                              onChange={e => setPayObtention(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : البلد
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-4 mt-4"
                        >
                          Diplôme
                        </CardTitle>
                        <Row>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {"1ère inscription à L'établissement :"}
                              </Label>
                            </div>
                          </Col>
                          <Col lg="8">
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer 1ère inscription à L'établissement"
                              type="number"
                              onChange={e => setPremiereIns(e.target.value)}
                            />
                          </Col>
                          <Col lg="2">
                            <div dir="rtl" className="mb-3 rtl">
                              <Label dir="ltr" for="basicpill-firstname-input1">
                                : اول سنة تسجيل بالمؤسسة
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Cycle :{" "}
                              </Label>
                              <Select
                                options={cycle}
                                isSearchable={true}
                                onChange={e => getNiveau(e)}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Niveau :{" "}
                              </Label>
                              <Select
                                options={niveau}
                                isSearchable={true}
                                onChange={e => getSpecialite(e)}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Spécialité :
                              </Label>
                              <Select
                                options={specialite}
                                isSearchable={true}
                                onChange={e => setSelectSpecialite(e)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <Col
                            lg="12"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="button"
                              className="btn btn-warning mb-2 me-2"
                              onClick={() => props.history.push("/login")}
                            >
                              Annuler
                            </Button>
                            <Button
                              id="Confirmer"
                              name="Confirmer"
                              type="button"
                              color="primary"
                              className="btn btn-primary mb-2 me-2"
                              disabled={!disbutReg}
                              onClick={save}
                            >
                              Confirmer
                            </Button>
                          </Col>
                        </div>
                      </AvForm>
                    </CardBody>
                    <div className="mt-1 text-center">
                      <p>
                        © {new Date().getFullYear()} Cresus. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger" /> by{" "}
                        <a href="https://cresus.pro/">cresus.pro</a>
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div style={{ padding: "19.2% 0%" }}>
          <Container>
            <div className="alert alert-success" role="alert">
              <h4 className="alert-heading">Succès !!</h4>
              <p className="mb-0">Cher enseignant,</p>
              <p className="mb-0">
                Nous avons bien reçu votre demande {"d'inscription"} et nous
                vous remercions de {"l’intérêt"} et du temps que vous nous
                témoignez. Votre compte sera activé par {"l'admin"} dans les
                plus brefs délais aprés la vérification de vos cordonnées . Si
                vous avez la moindre question,{"l'équipe"} administrative
                demeure à votre disposition.
              </p>
              <p className="mb-0">Bonne journée.</p>
            </div>
          </Container>
        </div>
      )}
      <ToastContainer
        transition={Flip}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </div>
  )
}

export default withRouter(Register)

Register.propTypes = {
  history: PropTypes.object,
}
