import React, { useEffect, useState } from "react"
import APIS from "../../apis"
import logo from "../../assets/images/logo.png"
import user1 from "../../assets/images/users/userImage.png"
import axios from "axios";

const ImpressionInscription = () => {
  const [nomPrenom, setNomPrenom] = useState("")
  const [nomPrenomAr, setNomPrenomAr] = useState("")
  const [prenomPere, setPrenomPere] = useState("")
  const [prenomPereAr, setPrenomPereAr] = useState("")
  const [gender, setGender] = useState(1)
  const [date, setDate] = useState("")
  const [gouvernoratNaiss, setGouvernoratNaiss] = useState("")
  const [gouvernoratNaissAr, setGouvernoratNaissAr] = useState("")
  const [selectNationnalite, setSelectNationnalite] = useState("")
  const [numero, setNumero] = useState("")
  const [etatCivil, setEtatCivil] = useState(1)
  const [rue, setRue] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [gouvernorat, setGouvernorat] = useState("")
  const [delegation, setDelegation] = useState("")
  const [tel, setTel] = useState("")
  const [email, setEmail] = useState("")
  const [anneeBac, setAnneeBac] = useState("")
  const [section, setSection] = useState("")
  const [mention, setMention] = useState("")
  const [session, setSession] = useState("")
  const [payObtention, setPayObtention] = useState("")
  const [premiereIns, setPremiereIns] = useState("")
  const [selectCycle, setSelectCycle] = useState("")
  const [selectNiveau, setSelectNiveau] = useState("")
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [etudiantId, setEtudiantId] = useState("")
  const [etat, setEtat] = useState("")
    const [imageSrc, setImageSrc] = useState("");
    const [cin, setCin] = useState("")
  // useEffect(async () => {
  //   var url = window.location.href
  //   var array = url.split("=")
  //   var cin = array[1]
  //   const res = await APIS.post("renseignement/get_by_cin", {
  //     cin: cin,
  //   }).then(res => {
  //     if (res.data.status === 200) {
  //       const resC = APIS.post("check_profile_image", {
  //         type: "etudiants",
  //         id: res.data.Renseignement.id,
  //       }).then(resC => {
  //         setEtat(resC.data.error)
  //       })
  //     }
  //     setEtudiantId(res.data.Renseignement.id)
  //     setNomPrenom(res.data.Renseignement.nom)
  //     setNomPrenomAr(res.data.Renseignement.nom_ar)
  //     setPrenomPere(res.data.Renseignement.prenonPere_fr)
  //     setPrenomPereAr(res.data.Renseignement.prenonPere_ar)
  //     setGender(res.data.Renseignement.sexe)
  //     setDate(res.data.Renseignement.dateNaissance)
  //     setGouvernoratNaiss(res.data.Renseignement.gouvernoratNaissance_fr)
  //     setGouvernoratNaissAr(res.data.Renseignement.gouvernoratNaissance_ar)
  //     setSelectNationnalite(res.data.Renseignement.nationalité.label)
  //     setNumero(cin)
  //     setEtatCivil(res.data.Renseignement.etatCivil)
  //     setRue(res.data.Renseignement.rue)
  //     setCodePostal(res.data.Renseignement.codePostal)
  //     setGouvernorat(res.data.Renseignement.gouvernorat)
  //     setDelegation(res.data.Renseignement.délégation)
  //     setTel(res.data.Renseignement.téléphone)
  //     setEmail(res.data.Renseignement.email)
  //     setSection(res.data.Renseignement.section)
  //     setMention(res.data.Renseignement.mention)
  //     setSession(res.data.Renseignement.session_etd)
  //     setAnneeBac(res.data.Renseignement.anneBac)
  //     setPayObtention(res.data.Renseignement.paysObtention)
  //     setPremiereIns(res.data.Renseignement.preméreInscription)
  //     setSelectNiveau(res.data.Renseignement.niveau.label)
  //     setSelectCycle(res.data.Renseignement.cycle.label)
  //     setSelectSpecialite(res.data.Renseignement.specialite.label)
  //   })
  // }, [])
    useEffect(async () => {

        var url = window.location.href
        var array = url.split("=")
        var cin = array[1]
        setCin(cin)
        const resA = await axios
            .get("https://scolarite.backcresus-institut.ovh/api/annee")
            .then(resA => {
                const resAnnee = axios.post(
                    "https://scolarite.backcresus-institut.ovh/api/annee/get_by_id",
                    { id: resA.data.Annee[0].CurrentAnnee[0].value }
                ).then(resAnnee=>{

                    axios.post("https://scolarite.backcresus-institut.ovh/api/renseignement/get_by_cin", {
                        // APIS.post("renseignement/get_by_cin", {
                        cin: cin,
                    },{
                        headers: {
                            'X-Frame-Options' : "DENY",
                            'annee': resAnnee.data.Annee.id,
                            'Content-Type': 'application/json',
                        },
                    }).then(res => {
                        if (res.data.status === 200) {
                            axios.post("https://scolarite.backcresus-institut.ovh/api/check_profile_image", {
                                type: "etudiant",
                                id: res.data.Renseignement.id,
                            },{
                                headers: {
                                    'X-Frame-Options' : "DENY",
                                    'annee': resAnnee.data.Annee.id,
                                    'Content-Type': 'application/json',
                                },
                            }).then(resC => {
                                setEtat(resC.data.error)
                            })
                        }
                        const imageUrl =
                            "https://scolarite.backcresus-institut.ovh/api/assetsmanager/etudiant/" +
                            res.data.Renseignement.id;
                        const headers = new Headers();
                        headers.append("annee", resAnnee.data.Annee.id); // Remplacez VOTRE_TOKEN par votre jeton d'authentification

                        fetch(imageUrl, {
                            method: "GET",
                            headers: headers,
                        })
                            .then((response) => {
                                if (!response.ok) {
                                    throw new Error(`Erreur HTTP! Statut: ${response.status}`);
                                }
                                return response.blob();
                            })
                            .then((blob) => {
                                const objectURL = URL.createObjectURL(blob);
                                setImageSrc(objectURL);
                            })
                            .catch((error) => {
                                console.error("Erreur lors de la récupération de l'image:", error);
                            });
                            setEtudiantId(res.data.Renseignement.id)
                            setNomPrenom(res.data.Renseignement.nom)
                            setNomPrenomAr(res.data.Renseignement.nom_ar)
                            setPrenomPere(res.data.Renseignement.prenonPere_fr)
                            setPrenomPereAr(res.data.Renseignement.prenonPere_ar)
                            setGender(res.data.Renseignement.sexe)
                            setDate(res.data.Renseignement.dateNaissance)
                            setGouvernoratNaiss(res.data.Renseignement.gouvernoratNaissance_fr)
                            setGouvernoratNaissAr(res.data.Renseignement.gouvernoratNaissance_ar)
                            setSelectNationnalite(res.data.Renseignement.nationalité.label)
                            setNumero(cin)
                            setEtatCivil(res.data.Renseignement.etatCivil)
                            setRue(res.data.Renseignement.rue)
                            setCodePostal(res.data.Renseignement.codePostal)
                            setGouvernorat(res.data.Renseignement.gouvernorat)
                            setDelegation(res.data.Renseignement.délégation)
                            setTel(res.data.Renseignement.téléphone)
                            setEmail(res.data.Renseignement.email)
                            setSection(res.data.Renseignement.section)
                            setMention(res.data.Renseignement.mention)
                            setSession(res.data.Renseignement.session_etd)
                            setAnneeBac(res.data.Renseignement.anneBac)
                            setPayObtention(res.data.Renseignement.paysObtention)
                            setPremiereIns(res.data.Renseignement.preméreInscription)
                            setSelectNiveau(res.data.Renseignement.niveau.label)
                            setSelectCycle(res.data.Renseignement.cycle.label)
                            setSelectSpecialite(res.data.Renseignement.specialite.label)
                    })
                    // setAnnee(resAnnee.data.Annee.id)

                })

            })


    }, [])
  const print = () => {
    window.print()
  }

  return (
    <div className="container">
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td
              style={{
                verticalAlign: "middle",
              }}
            >
              <img src={logo} width={100} />
            </td>
            <td colSpan={2} />
            <td
              style={{
                textAlign: "center",
                verticalAlign: "middle",
              }}
              nowrap
            >
              <h4
                style={{
                  padding: "0px",
                  margin: "0px",
                  fontSize: "12px",
                  fontFamily: '"Tahoma"',
                  direction: "rtl",
                }}
              >
                المعهد العالي للتصرف بقابس
              </h4>
            </td>
          </tr>
          <tr>
            <td colSpan={4} valign="middel">
              <div
                style={{
                  height: "1px",
                  width: "100%",
                  borderBottom: "solid 1px #eee",
                  marginBottom: "15px",
                }}
              />
            </td>
          </tr>
          <tr>
            <td
              colSpan={4}
              style={{
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <center>
                <h4
                  style={{
                    padding: "0px",
                    margin: "0px",
                    fontSize: "17px",
                    fontFamily: '"Tahoma"',
                  }}
                >
                  Fiche des Renseignements - بطاقة بيانات
                </h4>
              </center>
              <br />
              <center>
                <h4
                  style={{
                    padding: "0px",
                    margin: "0px",
                    fontSize: "17px",
                    fontFamily: '"Tahoma"',
                  }}
                >
                  Années universitaire 2023-2024
                </h4>
              </center>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ width: "130px" }}>
                {etat != 0 ? (
                  // <img
                  //   style={{ width: "100%" }}
                  //   src={
                  //     "https://scolarite.backcresus-institut.ovh/api/assetsmanager/etudiant/" +
                  //     etudiantId
                  //   }
                  // />
                    <img style={{ width: "100%" }} src={imageSrc}  />
                ) : (
                  <img style={{ width: "100%" }} src={user1} />
                )}
              </div>
              <br />
              <br />
            </td>
            <td />
            <td dir="rtl" />
            <td dir="rtl" />
          </tr>
          <tr>
            <td colSpan={4}>
              <h4
                style={{
                  padding: "0px",
                  margin: "0px",
                  fontSize: "14px",
                  fontFamily: '"Tahoma"',
                  color: "#0e8395",
                  fontWeight: 600,
                }}
              >
                Informations générales
              </h4>
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Nom et Prénom</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {nomPrenom}
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
              dir="rtl"
            >
              {nomPrenomAr}
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
              dir="rtl"
            >
              <b>الإسم و اللقب</b>
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Prénom Père</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {prenomPere}
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
              dir="rtl"
            >
              {prenomPereAr}
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
              dir="rtl"
            >
              <b>اسم الاب</b>
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Sexe</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {gender == 1 ? "Masculin" : "Féminin"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Date de naissance</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {date}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Gouvernorat de naissance</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {gouvernoratNaiss}
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
              dir="rtl"
            >
              {gouvernoratNaissAr}
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
              dir="rtl"
            >
              <b>الولاية</b>
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Nationalité</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {selectNationnalite}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>N° CIN</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {numero}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>N° Passeport pour les étrangers</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            />
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Etat Civil</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {etatCivil == 1
                ? "Célibataire"
                : etatCivil == 2
                ? "Marié(e)"
                : "Divorcé(e)"}
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <br />
              <br />
              <h4
                style={{
                  padding: "0px",
                  margin: "0px",
                  fontSize: "14px",
                  fontFamily: '"Tahoma"',
                  color: "#0e8395",
                  fontWeight: 600,
                }}
              >
                Adresse
              </h4>
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Rue</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {rue}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Code postal</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {" "}
              {codePostal}{" "}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Gouvernorat</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {" "}
              {gouvernorat}{" "}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Délégation</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {delegation}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Téléphone</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {tel}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>E-mail</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {email}
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <br />
              <br />
              <h4
                style={{
                  padding: "0px",
                  margin: "0px",
                  fontSize: "14px",
                  fontFamily: '"Tahoma"',
                  color: "#0e8395",
                  fontWeight: 600,
                }}
              >
                Baccalauréat
              </h4>
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Année du bac</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {anneeBac}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Section</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {section}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Mention</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {mention}{" "}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Session</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {" "}
              {session}{" "}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>{"Pays d'obtention"}</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {payObtention}{" "}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>{"1ère inscription à L'établissement"}</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {premiereIns}{" "}
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <br />
              <br />
              <h4
                style={{
                  padding: "0px",
                  margin: "0px",
                  fontSize: "14px",
                  fontFamily: '"Tahoma"',
                  color: "#0e8395",
                  fontWeight: 600,
                }}
              >
                Diplôme
              </h4>
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Diplôme</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {selectCycle}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Niveau</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {" "}
              {selectNiveau}{" "}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              <b>Spécialité</b>
            </td>
            <td
              style={{
                fontSize: "12px",
                fontFamily: '"Tahoma"',
              }}
            >
              {" "}
              {selectSpecialite}{" "}
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2%",
            marginBottom: "2%",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={print}
            className="fas fa-print"
          ></i>
        </div>
      </div>
    </div>
  )
}

export default ImpressionInscription
