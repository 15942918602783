import React, { useState } from "react"
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import cresusFond from "../../assets/images/cresuFond.jpg"
import { Grid } from "react-loader-spinner"
import APIS from "../../apis"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
import axios from "axios";

const Redirection = props => {
  const [cin, setCin] = useState("")
  const [loading, setLoading] = useState(true)
  const [annee, setAnnee] = useState("");

  const resA =  axios
      .get("https://scolarite.backcresus-institut.ovh/api/annee")
      .then(resA => {
        const resAnnee = axios.post(
            "https://scolarite.backcresus-institut.ovh/api/annee/get_by_id",
            { id: resA.data.Annee[0].CurrentAnnee[0].value }
        ).then(resAnnee=>{
          setAnnee(resAnnee.data.Annee.id)
        })
      })
  const verifCin = async () => {
    setLoading(false)
    const res = await APIS.post("renseignement/verif_cin", {
      cin,
    },{
      headers: {
        'X-Frame-Options' : "DENY",
        'annee': annee,
        'Content-Type': 'application/json',
      },
    })
    if (res.data.status === 200) {
      props.history.push("/DetailIns?cin=" + cin)
    } else {
      setLoading(true)
      toast.error("⛔ Veuillez vérifier votre CIN !", {
        containerId: "A",
      })
    }
  }

  return (
    <div>
      {loading ? (
        <div
          style={{
            backgroundImage: `url(${cresusFond})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <div style={{ paddingTop: "7%" }}>
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div>
                      <Row>
                        <Col className="justify-content-center pt-4">
                          <h3
                            style={{
                              color: "#485ec4",
                              textAlign: "center",
                            }}
                          >
                            <p>FICHE DES RENSEIGNEMENTS</p>
                            <p>A.U 2023/2024</p>
                          </h3>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-4">
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            CIN/ N° Passeport pour les étrangers :
                          </Label>
                          <Input
                            placeholder="Entrer CIN/ N° Passeport"
                            type="text"
                            className="form-control "
                            onChange={e => setCin(e.target.value)}
                          />
                        </div>
                      </Col>
                      <div className="p-2">
                        <div className="mt-3 d-grid">
                          <button
                            onClick={verifCin}
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Connexion
                          </button>
                        </div>
                      </div>
                    </CardBody>
                    <div className="mt-1 text-center">
                      <p>
                        © {new Date().getFullYear()} Cresus. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger" /> by{" "}
                        <a href="https://cresus.pro/">cresus.pro</a>
                      </p>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
            <ToastContainer
              transition={Flip}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-50px",
            marginLeft: "-50px",
          }}
        >
          <Grid heigth="100" width="100" color="#86B5E9" ariaLabel="loading" />
        </div>
      )}
    </div>
  )
}

export default withRouter(Redirection)

Redirection.propTypes = {
  history: PropTypes.object,
}
